import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import './components/ShareButton.css';  // Adjust the path as needed

// Your App component or index.js code


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);