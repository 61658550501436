import { useState, useEffect } from "react";
import axios from "axios";

const useIpLocation = () => {
  const [ipDetails, setIpDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIpDetails = async () => {
      try {
        // Check if IP details are already cached
        const cachedDetails = localStorage.getItem("ipDetails");
        if (cachedDetails) {
          setIpDetails(JSON.parse(cachedDetails));
          setLoading(false);
          return;
        }

        // Fetch IP details from the API
        const response = await axios.get("https://ipapi.co/json/");
        const data = response.data;

        // Cache the result in localStorage
        localStorage.setItem("ipDetails", JSON.stringify(data));
        setIpDetails(data);
      } catch (err) {
        console.error("Error fetching IP details:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchIpDetails();
  }, []);

  return { ipDetails, loading, error };
};

export default useIpLocation;
