import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col, Modal, Spinner, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import currencyCodes from "currency-codes";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from 'react-country-region-selector';


const DonationForm = () => {
  const [donationData, setDonationData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    country: "", // Add country field here
    email: "",
    phone: "",
    amount: "",
    currency: "INR",
    message: "",
    isHiddenMessage: false,
    ipAddress: "", // New field for IP address
  });

  const [errors, setErrors] = useState({});
  const [defaultCountryCode, setDefaultCountryCode] = useState("IN");
  const [loading, setLoading] = useState(false); // For showing the spinner
  const [showDialog, setShowDialog] = useState(false);
  const [isMalayalam, setIsMalayalam] = useState(false);
  const [donationResponse, setDonationResponse] = useState(null); // Store response data
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [countryCurrencyMap, setCountryCurrencyMap] = useState({});
  const [donationModal, setDonationModal] = useState({ show: false, donation: null });
  const [region, setRegion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const { country_name, country_code, currency, region, ip } = response.data;       
        setRegion(region);
        setIsMalayalam(region === "Kerala");     
        setDonationData((prevData) => ({
          ...prevData,
          country: country_name || "",
          currency: currency || "USD",
          ipAddress: ip || null, // Explicitly set to null if undefined
        }));

        setDefaultCountryCode(country_code?.toLowerCase() || "us");
      } catch (error) {
        console.error("Error fetching location data:", error);

        // Fallback in case of an error
        setDonationData((prevData) => ({
          ...prevData,
          ipAddress: null, // Ensure ipAddress is explicitly null
        }));
      }
    };



    const fetchCountryCurrencyMap = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const map = {};

        response.data.forEach((country) => {
          const countryName = country.name.common;
          const currencyCode = country.currencies && Object.keys(country.currencies)[0];

          if (countryName && currencyCode) {
            map[countryName] = currencyCode;
          }
        });

        setCountryCurrencyMap(map);
      } catch (error) {
        console.error("Error fetching country-currency map:", error);

        // Add fallback data for the most common countries
        setCountryCurrencyMap({
          "India": "INR",
          "United States": "USD",
          "United Kingdom": "GBP",
          "Australia": "AUD",
          "Canada": "CAD",
          "United Arab Emirates": "AED",
          "Saudi Arabia": "SAR",
          "Kuwait": "KWD",
          "Qatar": "QAR",
          "Bahrain": "BHD",
          "Oman": "OMR",
          "Egypt": "EGP",
          "Jordan": "JOD",
          "Lebanon": "LBP",
          "Turkey": "TRY",
          "Israel": "ILS",
          "Iraq": "IQD",
          "Yemen": "YER",
          "Syria": "SYP",
          "Pakistan": "PKR",
          "Bangladesh": "BDT",
          "Sri Lanka": "LKR",
          "Nepal": "NPR",
          "Maldives": "MVR",
          "Singapore": "SGD",
          "Malaysia": "MYR",
          "Thailand": "THB",
          "Philippines": "PHP",
          "Vietnam": "VND",
          "South Korea": "KRW",
          "Japan": "JPY",
          "China": "CNY",
          "Hong Kong": "HKD",
          "Russia": "RUB",
          "South Africa": "ZAR",
          "Brazil": "BRL",
          "Argentina": "ARS",
          "Mexico": "MXN",
          "Germany": "EUR",
          "France": "EUR",
          "Italy": "EUR",
          "Spain": "EUR",
          "Portugal": "EUR",
          "Netherlands": "EUR",
          "Belgium": "EUR",
          "Austria": "EUR",
          "Switzerland": "CHF",
          "Sweden": "SEK",
          "Norway": "NOK",
          "Denmark": "DKK",
          "Finland": "EUR",
          "Poland": "PLN",
          "Czech Republic": "CZK",
          "Greece": "EUR",
          "Ireland": "EUR",
          "Hungary": "HUF",
          "Romania": "RON",
          "New Zealand": "NZD",
          "Indonesia": "IDR",
          "Nigeria": "NGN",
          "Kenya": "KES",
          "Ethiopia": "ETB",
          "Ghana": "GHS",
        });
      }
    };

    fetchLocationData();
    fetchCountryCurrencyMap();
  }, []);


  const validateField = (name, value, country) => {
    let error = "";

    if (name === "firstname" && !value.trim()) error = "First name is required";
    if (name === "lastname" && !value.trim()) error = "Last name is required";
    if (name === "address" && !value.trim()) error = "Address is required";
    if (name === "country" && !value.trim()) error = "Country is required"; // Add country validation
    if (name === "email") {
      if (!value.trim()) {
        error = "Email is required";
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          error = "Invalid email format";
        }
      }
    }
    if (name === "phone" && !value) error = "Phone number is required";
    if (name === "amount" && (!value.trim() || isNaN(value) || Number(value) <= 0))
      error = "Valid amount required";

    // Currency validation
    if (name === "currency") {
      const selectedCountry = country || donationData.country;
      const expectedCurrency = countryCurrencyMap[selectedCountry];

      if (expectedCurrency && value !== expectedCurrency) {
        error = `Currency must match the country (${expectedCurrency} for ${selectedCountry}).`;
      }
    }


    return error;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setDonationData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "country" || name === "currency") {
        const currencyError = validateField("currency", updatedData.currency, updatedData.country);
        setErrors((prevErrors) => ({
          ...prevErrors,
          currency: currencyError,
        }));
      }

      return updatedData;
    });

    const fieldError = validateField(name, value, donationData.country);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };

  const handleCountryChange = (val) => {
    setDonationData((prevData) => ({
      ...prevData,
      country: val,
    }));

    const currencyError = validateField("currency", donationData.currency, val);
    setErrors((prevErrors) => ({
      ...prevErrors,
      country: validateField("country", val),
      currency: currencyError,
    }));
  };



  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  const validateAllFields = () => {
    const newErrors = {};
    Object.keys(donationData).forEach((key) => {
      const error = validateField(key, donationData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (phone) => {
    setDonationData((prevData) => ({
      ...prevData,
      phone,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: validateField("phone", phone),
    }));
  };

  const handleContinueDonation = async (e) => {
    e.preventDefault();

    if (validateAllFields()) {
      setLoading(true); // Start the loading spinner
      setErrorMessage(""); // Clear any previous error message

      try {
        const currentIp = donationData.ipAddress;

        if (!currentIp) {
          throw new Error("IP address is not available. Unable to verify existing donations.");
        }     

        const donationsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`);
        const existingDonation = donationsResponse.data.find(
          (donation) =>
            donation.ipAddress === currentIp && donation.confirmationNumber === null
        );

        if (existingDonation) {        
          setDonationModal({ show: true, donation: existingDonation });
          setLoading(false); // Stop the spinner as no further action is needed
          return;
        }  

        // Add region and current date to donation data
        const currentRegion = region || "Unknown"; // Fallback if region is not set
        const currentDate = new Date().toISOString(); // ISO 8601 format for sorting

        const updatedDonationData = {
          ...donationData,
          region: currentRegion,
          date: currentDate,
        };        
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/donations/sendDonationAlertEmail`,
          updatedDonationData
        );

        if (response.status === 200) {          
          setDonationResponse(response.data); // Save the response to state
          setDonationData(response.data); // Update the donation data
          setShowDialog(true); // Show dialog after successful response
        } else {
          console.warn("Failed to process donation:", response.data);
          setErrorMessage("Failed to process your donation. Please try again later."); // Set error message
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
        }
      } catch (error) {
        console.error("Error processing donation:", error.message);
        setErrorMessage(error.message || "An error occurred while processing your donation. Please try again later.");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    }
  };



  const handleCloseDialog = () => {
    setShowDialog(false);
    if (donationResponse) {
      navigate("/payment-selection", { state: { donationData: donationResponse } });
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent white background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensures it overlays other elements
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {/* Display error message */}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
      <Container className="py-5">
        <h2 className="text-center mb-4">Donate Now</h2>
        <Form onSubmit={handleContinueDonation} className="p-3 border rounded bg-light">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={donationData.firstname}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  isInvalid={!!errors.firstname}
                />
                <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={donationData.lastname}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  isInvalid={!!errors.lastname}
                />
                <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={donationData.address}
                  onChange={handleChange}
                  placeholder="Enter your address"
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <CountryDropdown
                  value={donationData.country}
                  onChange={(val) => handleCountryChange(val)}
                  classes={`form-control ${errors.country ? 'is-invalid' : ''}`}
                />
                {errors.country && (
                  <div className="invalid-feedback d-block">{errors.country}</div>
                )}
              </Form.Group>
            </Col>
          </Row>




          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={donationData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Phone Number (If you have a WhatsApp Number. please enter it here.) </Form.Label>
                <PhoneInput
                  country={defaultCountryCode}
                  value={donationData.phone}
                  onChange={handlePhoneChange}
                  inputProps={{ name: "phone", required: true }}
                />
                {errors.phone && <div className="text-danger mt-1">{errors.phone}</div>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4} md={2} className="order-1">
              <Form.Group>
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  name="currency"
                  value={donationData.currency}
                  onChange={handleChange}
                  isInvalid={!!errors.currency} // Mark as invalid if there is an error
                >
                  {currencyCodes.codes().map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Form.Select>
                {errors.currency && (
                  <Form.Control.Feedback type="invalid">
                    {errors.currency}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={8} md={4} className="order-2">
              <Form.Group>
                <Form.Label>Donation Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={donationData.amount}
                  onChange={handleChange}
                  placeholder="Enter amount"
                  isInvalid={!!errors.amount}
                />
                <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={donationData.message}
                  onChange={handleChange}
                  onBlur={handleBlur} // Add this line
                  rows={3}
                  placeholder="Write a message (optional)"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Do you want to show your message to the public?</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Show"
                    name="isHiddenMessage"
                    value="false"
                    checked={!donationData.isHiddenMessage}
                    onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: false }))}
                  />
                  <Form.Check
                    type="radio"
                    label="Hide"
                    name="isHiddenMessage"
                    value="true"
                    checked={donationData.isHiddenMessage}
                    onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: true }))}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="text-center mt-4">
            <Col>
              <Button variant="success" type="submit" size="lg">
                Continue Donation Process
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal show={showDialog} onHide={handleCloseDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Please proceed with the transaction!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isMalayalam ? (<div>
              <p>അടുത്ത പേജിൽ ഒരു അനുയോജ്യമായ സംഭാവന ഇടപാട് തിരഞ്ഞെടുത്തു മുന്നോട്ട് പോകുക! </p>
              <p> താങ്കളുടെ സാമ്പത്തിക ഇടപാട് പൂർത്തിയാക്കിയതിനു ശേഷം ഈ വെബ്‌സൈറ്റിലേക്ക് തിരിച്ചു വന്നു ട്രാൻസാക്ഷൻ ഐഡിയും സ്ക്രീൻഷോട്ടും അടുത്ത് വരുന്ന പേജിൽ അപ്‌ലോഡ് ചെയ്യാവുന്നതാണ്</p>
              <p>അതല്ലെങ്കിൽ താങ്കളുടെ ഈമെയിലിൽ വന്നിരിക്കുന്ന ഒരു ലിങ്ക് ഉപയോഗിച്ചും സ്ക്രീൻഷോട്ട് അപ്‌ലോഡ് ചെയ്യാവുന്നതാണ്.</p>
              <p>ദയവായി താങ്കളുടെ ഇമെയിൽ ഇൻബോക്സ്, കൂടാതെ ജങ്ക് അല്ലെങ്കിൽ സ്‌പാം ഫോൾഡറുകൾ പരിശോധിക്കുക! </p>
            </div>
            ) : (
              <div>

                <p>Please proceed with a suitable transaction method shown on the next page! </p>
                <p> After completing the transaction, you are requested to comeback to the next web page and upload the Transaction ID and a screenshot.</p>
                <p>Alternately, you will be receiving an email with a link to confirm the transaction by uploading a screenshot. </p>
                <p> Please check your email inbox, also the junk or spam folder!</p>
              </div>
            )}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseDialog}>
              Proceed with Transaction
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={donationModal.show} backdrop="static" centered>
          <Modal.Header>
            <Modal.Title>{isMalayalam ? "നിങ്ങളുടെ സംഭാവന പൂർത്തിയാക്കുക" : "Please Complete Your Donation"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isMalayalam ? (
              <p>
                താങ്കൾ സംഭാവനയുടെ പ്രക്രിയ ആരംഭിച്ചിരിക്കുന്നു, പക്ഷേ, ഞങ്ങളുടെ റെക്കോർഡ് പ്രകാരം അത് ഇതുവരെ പൂർത്തിയാക്കിയിട്ടില്ല.
                അനുയോജ്യമായ ഇടപാടുമാർഗ്ഗം ഉപയോഗിച്ച് ദയവായി ഇത് പൂർത്തിയാക്കുക.
                താങ്കൾ ഇത് ഇതിനകം പൂർത്തിയാക്കിയെങ്കിൽ, <strong>ഇടപാടിന്റെ ഐഡി</strong>യും ഒരു സ്ക്രീൻഷോട്ടും താഴെ നൽകിയിരിക്കുന്ന ലിങ്ക് ഉപയോഗിച്ച് അപ്‌ലോഡ് ചെയ്യുക.
              </p>
            ) : (
              <p>
                You have already initiated the donation process but haven't completed it yet as per our records.
                Please complete it with a suitable transaction method. If you've already completed it, please
                upload the <strong>Transaction ID</strong> and a <strong>screenshot </strong> by clicking the link below.
              </p>
            )}

            <p>
              <a
                href={`https://www.support-jerome.org/confirm-donation?id=${donationModal.donation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {isMalayalam ? "സംഭാവന പൂർത്തിയാക്കാൻ ഇവിടെ ക്ലിക്ക്  ചെയ്യുക" : "Clcik Here to Complete Donation"}
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            {isMalayalam ? (
              <p>
                താങ്കൾക്ക്  ഇപ്പോൾ സംഭാവന ചെയ്യാൻ സാധിക്കാത്ത സാഹചര്യമാണെങ്കിൽ, മുകളിൽ കൊടുത്തുട്ടുള്ള ലിങ്കിൽ ക്ലിക്ക് ചെയ്തുകൊണ്ട് ദയവായി സംഭാവനയുടെ വിശദാംശങ്ങൾ ഡിലീറ്റ്  അല്ലെങ്കിൽ റദ്ദാക്കുക.
              </p>
            ) : (
              <p>
                If you're unable to donate now, please delete or cancel the donation details by clicking on the same link above.
              </p>
            )}
          </Modal.Footer>
        </Modal>
        {/* WhatsApp Floating Chat Button */}
        <a
          href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#25D366",
            borderRadius: "50%",
            width: "45px",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </a>
      </Container>
    </div>
  );
};

export default DonationForm;
