// src/components/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const LoginPage = ({ setIsLoggedIn, setIsSuperAdminLoggedIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [attemptsLeft, setAttemptsLeft] = useState(5);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const getIPAddress = async () => {
        try {
            const response = await axios.get('https://api64.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error('Error fetching IP address:', error);
            return 'unknown';
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const ipAddress = await getIPAddress();
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/admin/login`,
                { username, password },
                { headers: { 'X-Forwarded-For': ipAddress } }
            );

            if (response.status === 200) {
                setIsLoggedIn(true); // Update login status
                setIsSuperAdminLoggedIn(username === 'help4jerome'); // Check if user is super admin
                navigate('/admin'); // Redirect to the admin page
            }
        } catch (error) {
            console.error('Error logging in:', error);
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData === 'object') {
                    setErrorMessage(errorData.message || 'An error occurred. Please try again.');
                } else {
                    setErrorMessage(errorData);
                }

                if (errorData.message && errorData.message.includes("Attempts remaining")) {
                    const remainingAttempts = parseInt(errorData.message.match(/\d+/)[0], 10);
                    setAttemptsLeft(remainingAttempts);
                } else if (errorData.message && errorData.message.includes("Access denied")) {
                    setAttemptsLeft(0);
                }
            } else {
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center">Admin Login</h2>
            {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
            <form onSubmit={handleLogin}>
                <div className="form-group mb-3">
                    <label>Username</label>
                    <input
                        type="text"
                        className="form-control"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group mb-3 position-relative">
                    <label>Password</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: 'pointer' }}
                    >
                        {showPassword ? <EyeSlash /> : <Eye />}
                    </span>
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={attemptsLeft <= 0}>
                    Login
                </button>
                {/* <div className="text-center mt-3">
                    <button type="button" className="btn btn-link" onClick={() => navigate('/signup')}>Sign Up</button>
                </div> */}
            </form>
        </div>
    );
};

export default LoginPage;
