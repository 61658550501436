import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Row, Col, Image, Modal } from 'react-bootstrap';
import './PaymentSelection.css'; // Import the CSS file
import axios from 'axios';


const PaymentSelection = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Retrieve donation data from state
    const { donationData } = state || {};
    const [selectedPayment, setSelectedPayment] = useState('');
    const [showUPIModal, setShowUPIModal] = useState(false);
    const [showGPayModal, setShowGPayModal] = useState(false);
    const [showPaytmModal, setShowPaytmModal] = useState(false);
    const [showInternetBankingModal, setShowInternetBankingModal] = useState(false);
    const [isMalayalam, setIsMalayalam] = useState(false); // Track user location for language preference
    const [showDialog, setShowDialog] = useState(false);

    // PayPal Ref to prevent duplication
    const paypalRef = useRef();
    const paypalInitialized = useRef(false);

    const paytmDeepLink = 'paytmmp://pay?pa=your-paytm-upi-id@paytm&pn=YourName&am=100&cu=INR&tn=Donation';
    const paytmQRCode = `${process.env.PUBLIC_URL}/paytm-qr-code.png`;
    const upiQRCode = `${process.env.PUBLIC_URL}/upi-qr-code.png`;
    const upiUrl = 'https://play.google.com/store/apps/details?id=com.phonepe.app';
    const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user';


    const fetchUserLocation = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const userState = (await response.json()).region;
            setIsMalayalam(userState === 'Kerala'); // Set language preference based on location
        } catch (error) {
            console.error('Error fetching user location:', error);
            setIsMalayalam(false); // Default to English on error
        }
    };


    const handleCloseDialog = () => {
        setShowDialog(false); // Close the dialog
        navigate('/'); // Redirect to home page
    };

    useEffect(() => {
        fetchUserLocation(); // Fetch user location on component mount
    }, [selectedPayment]);


    const handlePaymentSelect = (method) => {
        setSelectedPayment(method);
        if (method === 'UPI') {
            setShowUPIModal(true);
        } else if (method === 'Paytm') {
            setShowPaytmModal(true);
        } else if (method === 'Internet Banking') {
            setShowInternetBankingModal(true);
        } else if (method === 'Google Pay') {
            setShowGPayModal(true);
        }
    };

    const handleProceedToConfirmation = useCallback(
        (method) => {
            navigate('/confirmation', { state: { donationData, paymentMethod: method } });
        },
        [navigate, donationData]
    );
    useEffect(() => {
        if (window.paypal && paypalRef.current && !paypalInitialized.current) {
            paypalInitialized.current = true;
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    const fullName = `${donationData?.firstname || "Anonymous"} ${donationData?.lastname || ""}`.trim();
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: { value: donationData?.amount || "10.00" },
                                description: `Donation by ${fullName}`,
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    try {
                        const details = await actions.order.capture();
                        //const payerName = details.payer.name.given_name;
                        const transactionID = details.id;

                        const submissionData = {
                            ...donationData,
                            paymentMethod: "PayPal",
                            confirmationNumber: transactionID,
                            screenshotUrl: null,
                        };

                        const response = await axios.put(
                            `${process.env.REACT_APP_API_BASE_URL}/api/donations/${submissionData.id}`,
                            submissionData
                        );
                        if (response.status === 200) {
                            setShowDialog(true); // Show thank-you dialog on success
                        }
                    } catch (error) {
                        console.error("Error submitting donation:", error);
                        alert("Failed to submit donation details. Please contact support.");
                    }
                },
                onError: (err) => {
                    console.error("PayPal Error:", err);
                    alert("Something went wrong with your PayPal payment. Please try again.");
                },
            }).render(paypalRef.current);
        }
    }, [donationData, handleProceedToConfirmation]);




    return (
        <Container className="py-5">
            <Button variant="secondary" onClick={() => navigate("/donate")}>Back to Donation</Button>

            <h2 className="text-center mb-4">Select Payment Method</h2>
            <Row className="justify-content-center text-center mb-4">
                {/* PayPal Button */}
                <Col xs={12} className="mb-4">
                    <h6 style={{ textAlign: 'center' }}> </h6>
                    <div
                        ref={paypalRef}
                        style={{
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto', // Center the PayPal button container
                        }}
                    ></div>
                </Col>
            </Row>

            <Row className="justify-content-center text-center mb-4">
                <Col xs={12} className="mb-4">
                    <Button
                        variant="outline-success"
                        className="w-100 p-2 payment-button"
                        style={{
                            maxWidth: '600px',
                            height: '60px',
                            margin: '0 auto',
                            backgroundColor: '#32CD32', // Light gray background
                            borderColor: '#ddd', // Optional: Match border color to the background
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition effect
                        }}
                        onClick={() => handlePaymentSelect('UPI')}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#d4edda'; // Light green on hover
                            e.currentTarget.style.color = '#155724'; // Dark green text on hover
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#98FB98'; // Revert to light gray
                            e.currentTarget.style.color = '#000'; // Revert text to black
                        }}
                    >
                        <Image
                            src={`${process.env.PUBLIC_URL}/upi.png`}
                            alt="UPI"
                            className="payment-image"
                        />
                    </Button>
                </Col>

                <Col xs={12} className="mb-4">
                    <Button
                        variant="outline-success"
                        className="w-100 p-2 payment-button"
                        style={{
                            maxWidth: '600px',
                            height: '60px',
                            margin: '0 auto',
                            backgroundColor: '#F0E68C', // Light gray background
                            borderColor: '#ddd', // Optional: Match border color to the background
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition effect
                        }}

                        onClick={() => handlePaymentSelect('Google Pay')}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#00BFFF';
                            e.currentTarget.style.color = '#155724';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#4169E1';
                            e.currentTarget.style.color = '#000';
                        }}
                    >
                        <Image src={`${process.env.PUBLIC_URL}/googlepay.png`} alt="Google Pay" style={{ height: "80px", marginTop: "-18px" }} />
                    </Button>
                </Col>

                <Col xs={12} className="mb-4">
                    <Button
                        variant="outline-success"
                        className="w-100 p-2 payment-button"
                        style={{
                            maxWidth: '600px',
                            height: '70px',
                            margin: '0 auto',
                            backgroundColor: '#87CEFA', // Light gray background
                            borderColor: '#ddd', // Optional: Match border color to the background
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition effect
                        }}

                        onClick={() => handlePaymentSelect('Paytm')}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#d4edda';
                            e.currentTarget.style.color = '#155724';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#FAEBD7';
                            e.currentTarget.style.color = '#000';
                        }}
                    >
                        <Image
                            src={`${process.env.PUBLIC_URL}/paytm.png`}
                            alt="Paytm"
                            style={{ width: "100px", height: "40px", marginTop: "4px" }}
                        />
                    </Button>
                </Col>

                <Col xs={12} className="mb-4">
                    <Button
                        variant="outline-success"
                        className="w-100 p-2 payment-button"
                        style={{
                            maxWidth: '600px',
                            height: '70px',
                            margin: '0 auto',
                            backgroundColor: '#7FFFD4', // Light gray background
                            borderColor: '#ddd', // Optional: Match border color to the background
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition effect
                        }}

                        onClick={() => handlePaymentSelect('Internet Banking')}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#DEB887'; // Light green on hover
                            e.currentTarget.style.color = '#155724'; // Dark green text on hover
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#7FFFD4'; // Revert to light gray
                            e.currentTarget.style.color = '#000'; // Revert text to black
                        }}

                    >
                        <div style={{ marginTop: "-10px", fontSize: "18px" }}>Internet Banking</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/internetbank.png`}
                            alt="Internet Banking"
                            style={{ width: "80px", height: "37px", marginTop: "-10px" }}
                        />
                    </Button>
                </Col>
            </Row>

            {/* UPI Modal */}
            <Modal show={showUPIModal} onHide={() => setShowUPIModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with UPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMalayalam ? (
                        <div>
                            <p>UPI പേയ്മെന്റ് തുടരാൻ ഒരു പുതിയ ടാബ് തുറക്കും.</p>
                            <p>നിങ്ങളുടെ ഫോണിൽ <span style={{ color: '#007bff', fontSize: "18px", fontWeight: "bold" }}>Phone Pe</span>  ഇൻസ്റ്റാൾ ചെയ്തിട്ടുണ്ടെങ്കിൽ, QR കോഡ് സ്കാൻ ചെയ്യാൻ അത് ഉപയോഗിക്കുക.</p>
                            <p>
                                QR കോഡ് അടുത്ത പേജിൽ പ്രദർശിപ്പിക്കും, കൂടാതെ അത് നിങ്ങളുടെ
                                സിസ്റ്റത്തിലേക്ക് / ഫോണിലേക്ക് ഡൗൺലോഡ് ചെയ്യുകയും ഗാലറിയിൽ നിന്ന്
                                സ്കാൻ ചെയ്യാനുള്ള സൗകര്യം നൽകുകയും ചെയ്യും.
                            </p>
                            <p>
                                ഇടപാട് പൂർത്തിയാക്കിയ ശേഷം, ഈ വെബ്‌സൈറ്റിലേക്ക് മടങ്ങി വന്ന്, പേയ്മെന്റ് ട്രാക്ക് ചെയ്യുവാൻ  സഹായിക്കുന്നതിനായി <span style={{ color: '#FF0000' }}>സ്ക്രീൻഷോട്ടും, ഇടപാട് ഐഡിയും </span> ദയവായി  അപ്‌ലോഡ് ചെയ്യുക.
                            </p>

                        </div>
                    ) : (
                        <div>
                            <p>A new tab will open to proceed with the payment via UPI.</p>
                            <p>If you have <span style={{ color: '#007bff' }}>Phone Pe</span>  installed in your phone please use it for scanning the QR code.</p>

                            <p>
                                The QR code will be displayed on the next page as well as it
                                will be downloaded to your system and you will be able to scan it
                                from the gallery.
                            </p>
                            <p>
                                After completing the transaction, please come back to this website and upload a <span style={{ color: '#FF0000' }}>Screenshot </span> and <span style={{ color: '#FF0000' }}>Transaction ID </span> to help us tracking the payment.
                            </p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUPIModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            // Trigger QR code download
                            const link = document.createElement('a');
                            link.href = upiQRCode;
                            link.download = 'upi-qr-code.png'; // Set the default file name
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link); // Cleanup the temporary link

                            const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                            if (isMobile) {
                                window.location.href = upiUrl; // Redirect to UPI app on mobile
                            } else {
                                window.open(
                                    "https://www.phonepe.com/business-solutions/payment-gateway/",
                                    "_blank"
                                ); // Open in a new tab on desktop
                            }

                            handleProceedToConfirmation('UPI');
                        }}
                    >
                        Proceed
                    </Button>
                    <Image
                        src={upiQRCode}
                        alt="UPI QR Code"
                        style={{ width: '200px', height: '200px' }}
                    />
                </Modal.Footer>
            </Modal>


            {/* Google PayModal */}
            <Modal show={showGPayModal} onHide={() => setShowGPayModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with Google Pay</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMalayalam ? (<div>
                        <p>
                            ഗൂഗിൾ പേയിലൂടെ പേയ്മെന്റ് തുടരാൻ ഒരു പുതിയ ടാബ് തുറക്കും. നിങ്ങൾ മൊബൈൽ  ഫോണിൽ ആണെങ്കിൽ, ഗൂഗിൾ പേ ആപ്പ് നേരിട്ട് തുറക്കും. അല്ലെങ്കിൽ, നിങ്ങളെ ഗൂഗിൾ പേ വെബ്‌സൈറ്റിലേക്ക് റീഡയറക്ട് ചെയ്യും.
                        </p>
                        <p>QR കോഡ് അടുത്ത പേജിൽ പ്രദർശിപ്പിക്കും, കൂടാതെ അത് നിങ്ങളുടെ സിസ്റ്റത്തിലേക്ക് / ഫോണിലേക്ക് ഡൗൺലോഡ് ചെയ്യുകയും ഗാലറിയിൽ നിന്ന് സ്കാൻ ചെയ്യാനുള്ള സൗകര്യം നൽകുകയും ചെയ്യും.</p>
                        <p>
                            ഇടപാട് പൂർത്തിയാക്കിയ ശേഷം, ഈ വെബ്‌സൈറ്റിലേക്ക് മടങ്ങി വന്ന്, പേയ്മെന്റ് ട്രാക്ക് ചെയ്യുവാൻ  സഹായിക്കുന്നതിനായി <span style={{ color: '#FF0000' }}>സ്ക്രീൻഷോട്ടും, ഇടപാട് ഐഡിയും </span> ദയവായി  അപ്‌ലോഡ് ചെയ്യുക.
                        </p>
                    </div>
                    ) : (<div>
                        <p>
                            A new tab will open to proceed with the payment via Google Pay. </p>
                        <p>
                            The QR code will be displayed on the next page as well as it
                            will be downloaded to your system and you will be able to scan it
                            from the gallery.
                        </p>

                        <p>
                            If you are on a mobile device, it will open the <span style={{ color: '#007bff' }}>Google Pay </span> app directly. Otherwise, you will be redirected to the Google Pay website. </p>
                        <p>
                            After completing the transaction, please come back to this website and upload a <span style={{ color: '#FF0000' }}>Screenshot </span> and <span style={{ color: '#FF0000' }}>Transaction ID </span> to help us tracking the payment.
                        </p>
                    </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowGPayModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = upiQRCode;
                            link.download = 'upi-qr-code.png'; // Set the default file name
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link); // Cleanup the temporary link
                            const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                            if (isMobile) {
                                window.location.href = googlePlayUrl;
                            } else {
                                window.open("https://pay.google.com/", "_blank");
                            }
                            handleProceedToConfirmation('Google Pay');
                        }}
                    >
                        Proceed
                    </Button>
                    <Image
                        src={upiQRCode} // QR code or image for Google Pay
                        alt="Google Pay"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Footer>
            </Modal>

            {/* Paytm Modal */}
            <Modal show={showPaytmModal} onHide={() => setShowPaytmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with Paytm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMalayalam ? (<div>
                        <p>
                            <span style={{ color: '#007bff' }}>PayTM </span> പേയ്മെന്റ് തുടരാൻ ഒരു പുതിയ ടാബ്  അല്ലെങ്കിൽ PayTM App തുറക്കും, നിങ്ങളുടെ ഫോണിൽ  PayTM ഇൻസ്റ്റാൾ ചെയ്തിട്ടുണ്ടെങ്കിൽ.
                        </p>

                        <p> അടുത്ത പേജിലെ QR കോഡ് സ്കാൻ ചെയ്യുക അല്ലെങ്കിൽ ഫോണിലെ ഗാലറിയിൽ / ഡൗൺലോഡ് ചെയ്ത ഫോൾഡറിൽ നിന്നും   QR കോഡ് ഉപയോഗിച്ച് നിങ്ങളുടെ പേയ്മെന്റ് പൂർത്തിയാക്കുക.
                        </p>

                        <p>
                            ഇടപാട് പൂർത്തിയാക്കിയ ശേഷം, ഈ വെബ്‌സൈറ്റിലേക്ക് മടങ്ങി വന്ന്, പേയ്മെന്റ് ട്രാക്ക് ചെയ്യുവാൻ  സഹായിക്കുന്നതിനായി <span style={{ color: '#FF0000' }}>സ്ക്രീൻഷോട്ടും, ഇടപാട് ഐഡിയും </span> ദയവായി  അപ്‌ലോഡ് ചെയ്യുക.
                        </p>
                    </div>
                    ) : (<div>
                        <p>
                            A new tab will open to proceed with the payment via <span style={{ color: '#007bff' }}>PayTM </span>.  </p>
                        <p>
                            Alternatively, scan the QR code on the next page or the one downloaded in the gallery of the phone or downloaded folder to complete your payment.
                        </p>
                        <p>
                            After completing the transaction, please come back to this website and upload a <span style={{ color: '#FF0000' }}>Screenshot </span> and <span style={{ color: '#FF0000' }}>Transaction ID </span> to help us tracking the payment.
                        </p>
                    </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPaytmModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = paytmQRCode;
                            link.download = 'paytm-qr-code.png'; // Set the default file name
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link); // Cleanup the temporary link
                            const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                            if (isMobileDevice) {
                                window.location.href = paytmDeepLink; // Redirect to Paytm app on mobile
                            } else {
                                window.open('https://paytm.com/', "_blank"); // Open in a new tab on desktop
                            }
                            setShowPaytmModal(false);
                            handleProceedToConfirmation('Paytm');
                        }}
                    >
                        Proceed
                    </Button>
                    <Image
                        src={paytmQRCode} // QR code for Paytm
                        alt="Paytm QR Code"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Footer>
            </Modal>

            {/* Paytm Modal */}
            <Modal show={showInternetBankingModal} onHide={() => setShowInternetBankingModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with Paytm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMalayalam ? (<div>
                        <p>
                            ഇൻറർനെറ്റ് ബാങ്കിംഗ് വിശദാംശങ്ങൾ അടുത്ത പേജിൽ ലഭ്യമാകും.
                        </p>
                        <p>
                            ഇടപാട് പൂർത്തിയാക്കിയ ശേഷം, ഈ വെബ്‌സൈറ്റിലേക്ക് മടങ്ങി വന്ന്, പേയ്മെന്റ് ട്രാക്ക് ചെയ്യുവാൻ  സഹായിക്കുന്നതിനായി <span style={{ color: '#FF0000' }}>സ്ക്രീൻഷോട്ടും, ഇടപാട് ഐഡിയും </span> ദയവായി  അപ്‌ലോഡ് ചെയ്യുക.
                        </p>
                    </div>
                    ) : (<div>
                        <p>
                            Internet Banking details will be avilable on the next page.
                        </p>
                        <p>
                            After completing the transaction, please come back to this website and upload a <span style={{ color: '#FF0000' }}>Screenshot </span> and <span style={{ color: '#FF0000' }}>Transaction ID </span> to help us tracking the payment.
                        </p>
                    </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowInternetBankingModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleProceedToConfirmation('Internet Banking')}
                    >
                        Proceed
                    </Button>
                    <Image
                        src={`${process.env.PUBLIC_URL}/internetbank.png`}
                        alt="Internet banking"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Footer>
            </Modal>


            <Modal show={showDialog} onHide={handleCloseDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMalayalam ? (<div>
                        <p>താങ്കളുടെ സംഭാവന വിജയകരമായി സമർപ്പിച്ചിരിക്കുന്നു!</p>
                        <p>അല്പസമയത്തിനുള്ളിൽ താങ്കൾക്ക്  ജെറോമിന്റെ കൈപ്പടയിൽ എഴുതിയ ഒരു കത്ത് ഇമെയിൽ വഴിയായി ലഭിക്കുന്നതായിരിക്കും.</p>
                        <p>ദയവായി താങ്കളുടെ ഇമെയിൽ ഇൻബോക്സ്, കൂടാതെ ജങ്ക് അല്ലെങ്കിൽ സ്‌പാം ഫോൾഡറുകൾ പരിശോധിക്കുക!
                            നിങ്ങളുടെ പിന്തുണയ്ക്കും ഉദാരതയ്ക്കും ഞങ്ങൾ ഹൃദയപൂർവ്വം നന്ദി പറയുന്നു! </p>
                        <p>ദൈവത്തിന്റെ അനുഗ്രഹം താങ്കളുടെ മേൽ ഉണ്ടായിരിക്കട്ടെ 🙏</p>
                    </div>
                    ) : (<div>

                        <p>Your donation has been successfully submitted! </p>
                        <p>You will be receiving an email with a handwritten letter from Jerome, shortly. </p>
                        <p> Please check your email inbox, also the junk or spam folder!
                            We deeply appreciate your support and generosity! </p>
                        <p>God Bless you 🙏</p>
                    </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* WhatsApp Floating Chat Button */}
            <a
                href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    backgroundColor: "#25D366",
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                    zIndex: 1000,
                }}
            >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                    alt="WhatsApp"
                    style={{
                        width: "30px",
                        height: "30px",
                    }}
                />
            </a>
        </Container>
    );
};

export default PaymentSelection;
