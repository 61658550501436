import React from 'react';

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center', padding: '1em', backgroundColor: '#f1f1f1', fontSize: '0.9em' }}>
      <p>© 2024-2025. This website is owned and operated by Support Jerome Team, on behalf of Jerome. All rights reserved.</p>
    <br></br>
    <br></br>
    </footer>
  );
};

export default Footer;
