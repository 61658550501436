import React, { useState, useEffect } from 'react';
import { Table, Container, Alert, Modal, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import currency from 'currency.js';
import DonationProgressBar from './DonationProgressBar';

const DonationView = () => {
  const [donations, setDonations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({ INR: 1 });
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    fetchDonations();
    fetchExchangeRates();
  }, []);

  const fetchDonations = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`);
      setDonations(response.data);
    } catch (error) {
      console.error('Error fetching donations:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const fetchExchangeRates = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await axios.get('https://open.er-api.com/v6/latest/INR');
      setExchangeRates(response.data.rates);
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const calculateTotalInINR = () => {
    if (!exchangeRates) {
      console.error('Exchange rates not loaded.');
      return 0; // Ensure return is inside the function
    }

    const total = donations
      .filter((donation) => donation.confirmationNumber) // Exclude donations with null confirmationNumber
      .reduce((sum, donation) => {
        const rate = exchangeRates[donation.currency];
        if (!rate) {
          console.error(`Exchange rate for ${donation.currency} not found. Skipping this donation.`);
          return sum; // Ensure continuation of reduce
        }

        const effectiveRate = rate < 1 ? 1 / rate : rate;
        const amountInINR = currency(donation.amount).multiply(effectiveRate).value;
        return sum + amountInINR; // Accumulate the valid donations
      }, 0);

    return Math.round(total); // Return rounded total
  };




  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const renderSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">Donation Details</h2>

      {loading ? (
        renderSpinner()
      ) : donations.length === 0 ? (
        <Alert variant="info" className="text-center">
          No donation data available.
        </Alert>
      ) : (
        <>
          {/* Progress Bar */}
          <DonationProgressBar />
          <br />

          {/* Table View (Desktop) */}
          <Table striped bordered hover responsive className="d-none d-lg-table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Message</th>
                <th>Hidden Message</th>
                <th>Country</th>
                <th>Transaction ID</th>
                <th>Payment Method</th>
                <th>Screenshot</th>
              </tr>
            </thead>
            <tbody>
              {donations.map((donation, index) => (
                <tr key={donation.id}>
                  <td>{index + 1}</td>
                  <td>{donation.firstname || 'N/A'}</td>
                  <td>{donation.lastname || 'N/A'}</td>
                  <td>{donation.email || 'N/A'}</td>
                  <td>{donation.phone || 'N/A'}</td>
                  <td>{donation.address || 'N/A'}</td>
                  <td>{donation.currency}</td>
                  <td>{donation.amount}</td>
                  <td>{donation.message || 'N/A'}</td>
                  <td>{donation.isHiddenMessage ? 'true' : 'false'}</td>
                  <td>{donation.country || 'N/A'}</td>
                  <td>{donation.confirmationNumber}</td>
                  <td>{donation.paymentMethod}</td>
                  <td>
                    {donation.screenshotUrl ? (
                      <img
                        src={donation.screenshotUrl}
                        alt="Screenshot"
                        style={{
                          maxWidth: '100px',
                          maxHeight: '100px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(donation.screenshotUrl)}
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="14" className="text-end">
                  <strong>Total Amount (INR): {calculateTotalInINR().toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </Table>

          {/* Mobile View */}
          <div className="d-lg-none">
            {donations.map((donation, index) => (
              <Card key={donation.id} className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {index + 1}. {donation.firstname || 'N/A'} {donation.lastname || 'N/A'}
                  </Card.Title>
                  <Card.Text>
                    <strong>Email:</strong> {donation.email || 'N/A'}
                    <br />
                    <strong>Phone:</strong> {donation.phone || 'N/A'}
                    <br />
                    <strong>Address:</strong> {donation.address || 'N/A'}
                    <br />
                    <strong>Currency:</strong> {donation.currency}
                    <br />
                    <strong>Amount:</strong> {donation.amount}
                    <br />
                    <strong>Message:</strong> {donation.message || 'N/A'}
                    <br />
                    <strong>Hidden Message:</strong> {donation.isHiddenMessage ? 'true' : 'false'}
                    <br />
                    <strong>Country:</strong> {donation.country || 'N/A'}
                    <br />
                    <strong>Transaction ID:</strong> {donation.confirmationNumber}
                    <br />
                    <strong>Payment Method:</strong> {donation.paymentMethod}
                    <br />
                    {donation.screenshotUrl && (
                      <>
                        <strong>Screenshot:</strong>
                        <br />
                        <img
                          src={donation.screenshotUrl}
                          alt="Screenshot"
                          style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                          onClick={() => handleImageClick(donation.screenshotUrl)}
                        />
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>

          {/* Modal */}
          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Screenshot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalImage ? (
                <img src={modalImage} alt="Enlarged Screenshot" style={{ width: '100%' }} />
              ) : (
                <p>No screenshot available.</p>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default DonationView;
