import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { storage } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

const SuperAdmin = () => {
  const [homeContent, setHomeContent] = useState(null);
  const [newImages, setNewImages] = useState({});

  useEffect(() => {
    const fetchHomeContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`);
        setHomeContent(response.data);
      } catch (error) {
        console.error("Error fetching home content:", error);
      }
    };
    fetchHomeContent();
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setHomeContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setNewImages((prevImages) => ({
        ...prevImages,
        [name]: files[0],
      }));
    }
  };

  const saveParagraph = async (fieldName) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`, {
        [fieldName]: homeContent[fieldName],
      });
      alert("Paragraph saved successfully!");
    } catch (error) {
      console.error("Error saving paragraph:", error);
      alert("Failed to save paragraph.");
    }
  };

  const deleteParagraph = async (fieldName) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`, {
        [fieldName]: null,
      });
      setHomeContent((prevContent) => {
        const updatedContent = { ...prevContent };
        delete updatedContent[fieldName];
        return updatedContent;
      });
      alert("Paragraph deleted successfully!");
    } catch (error) {
      console.error("Error deleting paragraph:", error);
      alert("Failed to delete paragraph.");
    }
  };

  const saveImage = async (imageKey) => {
    try {
      const file = newImages[imageKey];
      const storageRef = ref(storage, `home_images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`, {
        [imageKey]: downloadURL,
      });

      setHomeContent((prevContent) => ({
        ...prevContent,
        [imageKey]: downloadURL,
      }));

      alert("Image saved successfully!");
    } catch (error) {
      console.error("Error saving image:", error);
      alert("Failed to save image.");
    }
  };

  const deleteImage = async (imageKey) => {
    try {
      const imagePath = homeContent[imageKey];
      const imageRef = ref(storage, imagePath);

      await deleteObject(imageRef);

      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`, {
        [imageKey]: null,
      });

      setHomeContent((prevContent) => {
        const updatedContent = { ...prevContent };
        delete updatedContent[imageKey];
        return updatedContent;
      });

      alert("Image deleted successfully!");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image.");
    }
  };

  if (!homeContent) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Container className="py-5">
      <h1>Edit Home Content</h1>
      <Form>
        <Row className="mb-4">
          <Col>
            <Form.Group controlId="headerText">
              <Form.Label>Header Text</Form.Label>
              <Form.Control
                type="text"
                name="headerText"
                value={homeContent.headerText || ""}
                onChange={handleTextChange}
              />
              <Button variant="primary" onClick={() => saveParagraph("headerText")} className="mt-2">
                Save Header Text
              </Button>
              <Button variant="danger" onClick={() => deleteParagraph("headerText")} className="mt-2 ms-2">
                Delete Header Text
              </Button>
            </Form.Group>
          </Col>
        </Row>

        {/* Malayalam Content */}
        {[...Array(6)].map((_, idx) => (
          <Row className="justify-content-center mb-4" key={`malayalam-${idx + 1}`}>
            <Col md={8}>
              <Form.Group controlId={`malayalamParagraph${idx + 1}`}>
                <Form.Label>Malayalam Paragraph {idx + 1}</Form.Label>
                <Form.Control
                  as="textarea"
                  name={`malayalamParagraph${idx + 1}`}
                  value={homeContent[`malayalamParagraph${idx + 1}`] || ""}
                  onChange={handleTextChange}
                  rows={3}
                />
                <Button
                  variant="primary"
                  onClick={() => saveParagraph(`malayalamParagraph${idx + 1}`)}
                  className="mt-2"
                >
                  Save Paragraph {idx + 1}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => deleteParagraph(`malayalamParagraph${idx + 1}`)}
                  className="mt-2 ms-2"
                >
                  Delete Paragraph {idx + 1}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        ))}

        {/* English Content */}
        {[...Array(6)].map((_, idx) => (
          <Row className="justify-content-center mb-4" key={`english-${idx + 1}`}>
            <Col md={8}>
              <Form.Group controlId={`englishParagraph${idx + 1}`}>
                <Form.Label>English Paragraph {idx + 1}</Form.Label>
                <Form.Control
                  as="textarea"
                  name={`englishParagraph${idx + 1}`}
                  value={homeContent[`englishParagraph${idx + 1}`] || ""}
                  onChange={handleTextChange}
                  rows={3}
                />
                <Button
                  variant="primary"
                  onClick={() => saveParagraph(`englishParagraph${idx + 1}`)}
                  className="mt-2"
                >
                  Save Paragraph {idx + 1}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => deleteParagraph(`englishParagraph${idx + 1}`)}
                  className="mt-2 ms-2"
                >
                  Delete Paragraph {idx + 1}
                </Button>
              </Form.Group>
            </Col>
            <Col md={4} className="text-center">
              <Form.Group controlId={`imagePath${idx + 1}`}>
                <Form.Label>Image {idx + 1}</Form.Label>
                <Form.Control type="file" name={`imagePath${idx + 1}`} onChange={handleImageChange} />
                <img
                  src={homeContent[`imagePath${idx + 1}`] || ""}
                  alt={`Preview ${idx + 1}`}
                  className="img-fluid rounded mt-2"
                  style={{ maxHeight: "150px" }}
                />
                <Button variant="primary" onClick={() => saveImage(`imagePath${idx + 1}`)} className="mt-2">
                  Save Image {idx + 1}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => deleteImage(`imagePath${idx + 1}`)}
                  className="mt-2 ms-2"
                >
                  Delete Image {idx + 1}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        ))}
      </Form>
    </Container>
  );
};

export default SuperAdmin;
