import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { PersonFill, List, X } from "react-bootstrap-icons";

const Header = ({ hiddenAdmin, isLoggedIn, isSuperAdminLoggedIn }) => {
  const [isNavExpanded, setIsNavExpanded] = React.useState(false);

  const handleToggle = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top" expanded={isNavExpanded}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand className="d-flex align-items-center">
            Support Jerome
            <img
              src={`${process.env.PUBLIC_URL}/logo512.png`}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          {isNavExpanded ? <X size={32} /> : <List size={32} />}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/donate">
              <Nav.Link>Donate</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
            {isLoggedIn && (
              <>
                <LinkContainer to="/donation-view">
                  <Nav.Link>Donation View</Nav.Link>
                </LinkContainer>
              </>
            )}
            {!hiddenAdmin && (
              <LinkContainer to="/admin">
                <Nav.Link>Admin</Nav.Link>
              </LinkContainer>
            )}
            {isSuperAdminLoggedIn && (
              <>
                <LinkContainer to="/super-admin">
                  <Nav.Link>Home Page Admin</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/donation-view-admin">
                  <Nav.Link>Donation View Admin</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/add-update">
                  <Nav.Link>Add Update</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/message-submission">
                  <Nav.Link>Send Messages without Donation</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
          <Nav>
            {!hiddenAdmin && (
              <LinkContainer to="/login">
                <Button variant="outline-light" className="d-flex align-items-center">
                  <PersonFill className="me-2" /> Admin Login
                </Button>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
