import React, { useState, useEffect } from 'react';
import { ProgressBar, Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import currency from 'currency.js';
import useIpLocation from "./useIpLocation";

const DonationProgressBar = () => {
    const [totalDonations, setTotalDonations] = useState(0); // Store total donations
    const [goal, setGoal] = useState(2000000); // Default goal in INR (₹20,00,000)
    const [loading, setLoading] = useState(true); // Loading state
    const [isIndia, setIsIndia] = useState(true); // Determine if user is in India
    const INR_TO_USD_RATE = 82; // Static exchange rate for INR to USD (or dynamically fetch)
    const { ipDetails, loading: ipLoading, error: ipError } = useIpLocation(); // Use IP location hook

    useEffect(() => {
        const calculateTotalInINR = (donations, exchangeRates) => {
            const total = donations
                .filter((donation) => donation.confirmationNumber) // Exclude donations with null confirmationNumber
                .reduce((sum, donation) => {
                    const rate = exchangeRates[donation.currency];
                    if (!rate) {
                        console.error(`Exchange rate for ${donation.currency} not found. Skipping this donation.`);
                        return sum;
                    }

                    const effectiveRate = rate < 1 ? 1 / rate : rate;
                    const amountInINR = currency(donation.amount).multiply(effectiveRate).value;
                    return sum + amountInINR; // Accumulate the valid donations
                }, 0);

            return Math.round(total); // Return rounded total
        };

        const fetchDonationDetails = async () => {
            try {
                setLoading(true);

                // Check IP details loading and errors
                if (ipLoading || ipError) {
                    console.error('IP details are not available yet or an error occurred.');
                    return;
                }

                // Validate ipDetails data
                const isInIndia = ipDetails?.country_code === 'IN';
                setIsIndia(isInIndia);

                // Fetch donations and exchange rates
                const exchangeRatesResponse = await axios.get('https://open.er-api.com/v6/latest/INR');
                const exchangeRates = exchangeRatesResponse.data.rates;

                const donationsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`);
                const donations = donationsResponse.data;

                // Calculate total donations in INR
                const totalInINR = calculateTotalInINR(donations, exchangeRates);

                if (isInIndia) {
                    setTotalDonations(totalInINR); // Display total in INR
                    setGoal(2000000); // Set goal to ₹20,00,000
                } else {
                    const totalInUSD = Math.round(totalInINR / INR_TO_USD_RATE); // Convert INR to USD
                    setTotalDonations(totalInUSD); // Display total in USD
                    setGoal(25000); // Set goal to $25,000
                }
            } catch (error) {
                console.error('Error fetching donation details:', error);
            } finally {
                setLoading(false);
            }
        };

        if (!ipLoading && !ipError) {
            fetchDonationDetails();
        }
    }, [ipDetails, ipLoading, ipError]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="py-3">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        fontSize: "1rem", // Reduced font size for the goal text
                        fontWeight: "bold",
                        color: "green",
                    }}
                >
                    Goal: {isIndia ? "₹" : "$"}
                    {goal.toLocaleString()}
                </span>
            </div>
            <div style={{ position: "relative", marginTop: "10px" }}>
                <ProgressBar
                    now={(totalDonations / goal) * 100}
                    variant="success" // Updated variant to green
                    style={{ height: "25px" }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                }}
            >
                <span
                    style={{
                        fontSize: "0.9rem", // Reduced font size for the raised text
                        fontWeight: "bold",
                        color: "green",
                    }}
                >
                    Raised {isIndia ? "₹" : "$"}
                    {totalDonations.toLocaleString()}
                </span>
            </div>
        </Container>


    );
};

export default DonationProgressBar;
