// src/components/Confirmation.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card, Modal, Alert, Image, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { storage } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios';

const Confirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { donationData: initialDonationData, paymentMethod } = location.state || {};

    const [donationData, setDonationData] = useState(initialDonationData || {});
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [screenshot, setScreenshot] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [isMalayalam, setIsMalayalam] = useState(false); // Track user location for language preference
    const [loading, setLoading] = useState(false); // For showing the spinner

    const fetchUserLocation = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const userState = (await response.json()).region;
            setIsMalayalam(userState === 'Kerala'); // Set language preference based on location
        } catch (error) {
            console.error('Error fetching user location:', error);
            setIsMalayalam(false); // Default to English on error
        }
    };

    useEffect(() => {
        fetchUserLocation(); // Fetch user location on component mount
    }, []);

    useEffect(() => {
        if (!initialDonationData || !paymentMethod) {
            console.error("Donation data or payment method is missing.");
        }
    }, [initialDonationData, paymentMethod]);

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]);
        setErrors((prevErrors) => ({ ...prevErrors, screenshot: '' })); // Clear previous error if any
    };

    const handleBack = () => {
        navigate('/payment-selection', { state: { donationData } });
    };

    const validateFields = () => {
        const fieldErrors = {};
        if (!confirmationNumber) fieldErrors.confirmationNumber = 'Confirmation number is required.';
        if (!screenshot) fieldErrors.screenshot = 'Screenshot is required.';
        if (!donationData.firstname) fieldErrors.firstname = 'First Name is required.';
        if (!donationData.lastname) fieldErrors.lastname = 'Last Name is required.';
        if (!donationData.address) fieldErrors.address = 'Address is required.';
        if (!donationData.country) fieldErrors.country = 'Country is required.';
        if (!donationData.email) fieldErrors.email = 'Email is required.';
        if (!donationData.phone) fieldErrors.phone = 'Phone number is required.';
        if (!donationData.currency) fieldErrors.currency = 'Currency is required.';
        if (!donationData.amount) fieldErrors.amount = 'Amount is required.';
        if (!paymentMethod) fieldErrors.paymentMethod = 'Payment Method is required.';
        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    const uploadScreenshotToStorage = async (file) => {
        const storageRef = ref(storage, `screenshots/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleDelete = async (id) => {
        if (!id) {
            alert("Invalid donation ID. Cannot delete.");
            return;
        }

        if (window.confirm('Are you sure you want to delete the details of this donation? This action cannot be undone.')) {
            setLoading(true); // Show spinner
            try {
                // API call to delete the donation
                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/donations/${id}`);

                // Optionally update UI to reflect deletion
                setDonationData({}); // Clear donation data
                alert('Donation details deleted successfully.');
                navigate('/'); // Redirect to the home page               
            } catch (error) {
                console.error('Error deleting donation:', error);
                alert('Failed to delete the donation. Please try again later.');
            } finally {
                setLoading(false); // Hide spinner
            }
        }
    };


    const handleConfirm = async () => {
        setLoading(true); // Start loading spinner

        if (!validateFields()) {
            setLoading(false); // Stop the spinner if validation fails
            return; // Exit early
        }

        try {
            const screenshotUrl = await uploadScreenshotToStorage(screenshot);

            const submissionData = {
                ...donationData,
                paymentMethod,
                confirmationNumber,
                screenshotUrl,
            };

            // Submit the donation and send emails
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/donations/${submissionData.id}`,
                submissionData
            );

            if (response.status === 200) {
                setShowDialog(true); // Show thank-you dialog on success
            }
        } catch (error) {
            console.error('Error submitting donation:', error);
            alert('Failed to submit donation details.');
        } finally {
            setLoading(false); // Stop the spinner after completion
        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDonationData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCloseDialog = () => {
        setShowDialog(false); // Close the dialog
        navigate('/'); // Redirect to home page
    };

    return (
        <div>
            {loading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent white background
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999, // Ensures it overlays other elements
                    }}
                >
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <Container className="py-5">
                <Button variant="secondary" onClick={handleBack}>
                    Back to Payment Selection
                </Button>

                <h2 className="text-center mb-4">Confirm Donation</h2>

                <Alert variant="info">
                    In case you need to correct any details, please use the back button to edit the data.
                </Alert>


                {paymentMethod === 'Internet Banking' && (
                    <Alert
                        variant="info"
                        className="text-center"
                        style={{
                            backgroundColor: '#ffffff',
                            padding: '20px',
                            borderRadius: '8px',
                            marginBottom: '20px',
                        }}
                    >
                        <h5>Internet Banking Details</h5>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '10px',
                                lineHeight: '1.6',
                                textAlign: 'left',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <strong>Account Holder:</strong> HANY MATHEW
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('HANY MATHEW')}
                                >
                                    Copy
                                </Button>
                            </div>
                            <div style={{ width: '100%' }}>
                                <strong>Account Number:</strong> 164001508208

                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('164001508208')}
                                >
                                    Copy
                                </Button>
                            </div>
                            <div style={{ width: '100%' }}>
                                <strong>IFSC:</strong> ICIC0001640
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('ICIC0001640')}
                                >
                                    Copy
                                </Button>
                            </div>
                            <div style={{ width: '100%' }}>
                                <strong>VPA:</strong> hanymathew@icici
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('hanymathew@icici')}
                                >
                                    Copy
                                </Button>
                            </div>
                            <div style={{ width: '100%' }}>
                                <strong>Account Type:</strong> SAVING
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('SAVING')}
                                >
                                    Copy
                                </Button>
                            </div>
                            <div style={{ width: '100%' }}>
                                <strong> Communication Address :</strong> HNO-A-11 OM ENCLAVE,NANDGRAM,GHAZIABAD   GHAZIABAD,  UTTAR PRADESH  201001
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                    onClick={() => navigator.clipboard.writeText('HNO-A-11 OM ENCLAVE,NANDGRAM,GHAZIABAD   GHAZIABAD,  UTTAR PRADESH  201001')}
                                >
                                    Copy
                                </Button>
                            </div>

                        </div>
                    </Alert>
                )}

                {paymentMethod === 'Google Pay' && (
                    <Alert variant="info" className="text-center">
                        <p>You selected Google Pay for your donation.</p>
                        <p>Scan the QR code below to complete your payment:</p>
                        <Image
                            src={`${process.env.PUBLIC_URL}/upi-qr-code.png`} // Replace with your QR code image path
                            alt="UPI QR Code"
                            style={{ width: '200px', height: '200px' }}
                            className="d-block mx-auto"
                        />
                        <p>After completing the payment, upload the screenshot and enter the Transaction ID below.</p>
                    </Alert>
                )}
                {paymentMethod === 'UPI' && (
                    <Alert variant="info" className="text-center">
                        <p>You selected UPI for your donation.</p>
                        <p>Scan the QR code below to complete your payment:</p>
                        <Image
                            src={`${process.env.PUBLIC_URL}/upi-qr-code.png`} // Replace with your QR code image path
                            alt="UPI QR Code"
                            style={{ width: '200px', height: '200px' }}
                            className="d-block mx-auto"
                        />
                        <p>After completing the payment, upload the screenshot and enter the Transaction ID below.</p>
                    </Alert>
                )}

                {paymentMethod === 'Paytm' && (
                    <Alert variant="info" className="text-center">
                        <p>You selected Paytm for your donation.</p>
                        <p>Scan the QR code below to complete your payment:</p>
                        <Image
                            src={`${process.env.PUBLIC_URL}/paytm-qr-code.png`} // Replace with your QR code image path
                            alt="Paytm QR Code"
                            style={{ width: '200px', height: '200px', marginBottom: '0' }} // Removed bottom margin
                            className="d-block mx-auto"
                        />
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '10px',
                                display: 'inline-block',
                                textAlign: 'center',
                            }}
                        >
                            <p style={{ margin: '5px 0', fontSize: '12px' }}>Name: Hany Mathew</p>
                            <p style={{ margin: '5px 0', fontSize: '12px' }}>UPI Handle: 7011001050@ptyes</p>
                        </div>
                        <p>After completing the payment, upload the screenshot and enter the Transaction ID below.</p>
                    </Alert>
                )}

                <Card className="p-4">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="name">
                                    <Form.Label><strong>First Name:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        value={donationData.firstname || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.firstnamename && <Form.Text className="text-danger">{errors.firstname}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="lastname">
                                    <Form.Label><strong>Last Name:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        value={donationData.lastname || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.lastname && <Form.Text className="text-danger">{errors.lastname}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="address" className="mt-3">
                                    <Form.Label><strong>Address:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={donationData.address || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.address && <Form.Text className="text-danger">{errors.address}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="country" className="mt-3">
                                    <Form.Label><strong>Country:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        value={donationData.country || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.country && <Form.Text className="text-danger">{errors.country}</Form.Text>}
                                </Form.Group>

                                <Form.Group controlId="email" className="mt-3">
                                    <Form.Label><strong>Email:</strong></Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={donationData.email || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="phone" className="mt-3">
                                    <Form.Label><strong>Phone:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={donationData.phone || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="currency" className="mt-3">
                                    <Form.Label><strong>Currency:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="currency"
                                        value={donationData.currency || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.currency && <Form.Text className="text-danger">{errors.currency}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="amount" className="mt-3">
                                    <Form.Label><strong>Amount:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        value={donationData.amount || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.amount && <Form.Text className="text-danger">{errors.amount}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="message" className="mt-3">
                                    <Form.Label><strong>Message:</strong></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        value={donationData.message || ""}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3">
                                    <Form.Label>Do you want to show your message to the public?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Show"
                                            name="isHiddenMessage"
                                            value="false"
                                            checked={!donationData.isHiddenMessage}
                                            onChange={() => setDonationData((prevData) => ({
                                                ...prevData,
                                                isHiddenMessage: false,
                                            }))}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Hide"
                                            name="isHiddenMessage"
                                            value="true"
                                            checked={donationData.isHiddenMessage}
                                            onChange={() => setDonationData((prevData) => ({
                                                ...prevData,
                                                isHiddenMessage: true,
                                            }))}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="paymentMethod" className="mt-3">
                                    <Form.Label><strong>Payment Method:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={paymentMethod}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.paymentMethod && <Form.Text className="text-danger">{errors.paymentMethod}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="confirmationNumber" className="mt-3">
                                    <Form.Label><strong>Transaction Number / ID:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={confirmationNumber}
                                        onChange={(e) => setConfirmationNumber(e.target.value)}
                                        placeholder="Enter Transaction ID"
                                        required
                                    />
                                    {errors.confirmationNumber && (
                                        <Form.Text className="text-danger">{errors.confirmationNumber}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="screenshot" className="mt-3">
                                    <Form.Label><strong>Upload Screenshot:</strong></Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        required
                                    />
                                    {errors.screenshot && (
                                        <Form.Text className="text-danger">{errors.screenshot}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-center mt-4">
                            <Col xs={12} className="mb-2">
                                <Button variant="danger" size="sm" onClick={() => handleDelete(donationData.id)}>
                                    Cancel / Delete All the Details of this Transaction
                                </Button>
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Button variant="secondary" onClick={handleBack}>
                                    Back to Payment Selection
                                </Button>
                            </Col>
                            <Col xs={12}>
                                <Button variant="success" onClick={handleConfirm}>
                                    Confirm and Submit Donation Details
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Card>

                {/* Thank You Modal */}

                <Modal show={showDialog} onHide={handleCloseDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Thank You!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isMalayalam ? (<div>
                            <p>താങ്കളുടെ സംഭാവന വിജയകരമായി സമർപ്പിച്ചിരിക്കുന്നു!</p>
                            <p>അല്പസമയത്തിനുള്ളിൽ താങ്കൾക്ക് ജെറോമിൽ നിന്ന് ഒരു കൈയെഴുത്ത് കത്ത് ഉൾപ്പെടെയുള്ള ഇമെയിൽ ലഭിക്കും.</p>
                            <p> നിങ്ങളുടെ പിന്തുണയ്ക്കും ഉദാരതയ്ക്കും ഞങ്ങൾ ഹൃദയപൂർവ്വം നന്ദി പറയുന്നു! </p>
                            <p>ദൈവത്തിന്റെ അനുഗ്രഹം താങ്കളുടെ മേൽ ഉണ്ടായിരിക്കട്ടെ 🙏</p>
                        </div>
                        ) : (<div>

                            <p>Your donation has been successfully submitted! </p>
                            <p>You will be receiving an email with a handwritten letter from Jerome shortly.</p>
                            <p> We deeply appreciate your support and generosity! </p>
                            <p>God Bless you 🙏</p>
                        </div>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* WhatsApp Floating Chat Button */}
                <a
                    href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        backgroundColor: "#25D366",
                        borderRadius: "50%",
                        width: "45px",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                        zIndex: 1000,
                    }}
                >
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                        alt="WhatsApp"
                        style={{
                            width: "30px",
                            height: "30px",
                        }}
                    />
                </a>
            </Container>
        </div>
    );
};

export default Confirmation;
