// src/components/Home.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Button, Modal, Collapse, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons
import DonationProgressBar from './DonationProgressBar';
import useIpLocation from "./useIpLocation";
import ShareButton from './ShareButton';

const Home = () => {
  const [isMalayalam, setIsMalayalam] = useState(false);
  const [homeContent, setHomeContent] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages
  const [updates, setUpdates] = useState([]); // Store updates data
  const [loadingUpdates, setLoadingUpdates] = useState(false); // Loading state for updates
  const [open, setOpen] = useState(false); // Collapse state for updates section
  const [donationModal, setDonationModal] = useState({ show: false, donation: null });
  const { ipDetails } = useIpLocation();
  const [visibleMessages, setVisibleMessages] = useState(5); // Number of messages to display initially
  const messageBoxRef = useRef(null); // Initialize the ref for the scrollable box
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const location = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);


  const updateView = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    // Add event listener for resizing
    window.addEventListener('resize', updateView);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateView);
    };
  }, []);


  const fetchUpdates = async () => {
    setLoadingUpdates(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/updates`);
      // Sort updates in chronological order by date
      const sortedUpdates = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setUpdates(sortedUpdates);
    } catch (error) {
      console.error("Error fetching updates:", error);
    } finally {
      setLoadingUpdates(false); // Ensure loading state is updated regardless of success or error
    }
  };

  const handleToggleUpdates = () => {
    if (!open && updates.length === 0) {
      fetchUpdates(); // Fetch updates only when first expanding if not already fetched
    }
    setOpen(!open); // Toggle open state
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page scrolls to the top on load
  }, []);

  useEffect(() => {
    if (ipDetails?.region === "Kerala") {
      setIsMalayalam(true);
    } else {
      setIsMalayalam(false);
    }
  }, [ipDetails]);

  // Manual toggle for the user
  const toggleLanguage = () => {
    setIsMalayalam(!isMalayalam);
  };

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the dialog if the state is passed

      // Clear the state to prevent dialog from showing on page reload
      navigate("/", { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleCloseDialog = () => setShowDialog(false);


  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the thank-you dialog
    }

    const fetchMessages = async () => {
      try {
        // Fetch messages and donations
        const [donationsResponse, messagesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/messages`),
        ]);

        const currentIp = ipDetails?.ip; // Use IP details from the hook

        // Check for incomplete donations
        const incompleteDonation = donationsResponse.data.find(
          (donation) =>
            donation.ipAddress === currentIp && donation.confirmationNumber === null
        );
        if (incompleteDonation) {
          setDonationModal({ show: true, donation: incompleteDonation });
        }

        // Filter and combine messages
        const donationMessages = donationsResponse.data.filter(
          (message) => !message.isHiddenMessage && message.confirmationNumber !== null
        );
        const additionalMessages = messagesResponse.data;
        const combinedMessages = [...donationMessages, ...additionalMessages];

        // Sort and set messages
        const sortedMessages = combinedMessages.sort((a, b) => a.firstname.localeCompare(b.firstname));
        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };


    fetchMessages();
  }, [location.state, ipDetails?.ip]);

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the dialog if the state is passed
    }
  }, [location.state]);


  // Fetch home content data from backend
  useEffect(() => {
    const fetchHomeContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`);
        setHomeContent(response.data);
      } catch (error) {
        console.error("Error fetching home content:", error);
      }
    };

    fetchHomeContent();
  }, []);


  if (!homeContent) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ); // Show loading message while data is fetched
  }

  return (
    <Container
      style={{
        margin: isDesktop ? '0 auto' : '0', // Center content for desktop, no margin for mobile
        maxWidth: isDesktop ? 'calc(100% - 500px)' : '100%', // Desktop: 500px side margins, Mobile: full width
        marginTop: isDesktop ? '30px' : '0', // Add top margin for desktop, no extra margin for mobile
        padding: isDesktop ? '20px' : '15px', // Adjust padding for better spacing on desktop and mobile
      }}
    >

      <Container className="py-5" style={{ marginTop: '-70px' }}>
        <DonationProgressBar />
      </Container>
      <Row className="text-center mb-4">
        <Modal show={showDialog} onHide={handleCloseDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Thank You!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your donation has been successfully submitted. We deeply appreciate your support and generosity!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseDialog}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Col>
          <h6
            style={{
              marginTop: "-40px",
              fontWeight: "bold",
              fontSize: "1.3rem", // Adjust the size if needed
              color: "#222", // Darker font color
            }}
          >
            {homeContent.headerText}
          </h6>

          <Button variant="link" onClick={toggleLanguage} className="mt-2">
            {isMalayalam ? "Read in English" : "മലയാളത്തിൽ വായിക്കുക"} {/* Toggle button text */}
          </Button>

        </Col>
      </Row>
      {isMalayalam ? (
        // Malayalam Content
        <>
          <Row className="justify-content-center mb-4">
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath1}
                alt="Jerome's Journey"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph1}
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph2}
              </p>
            </Col>
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath2}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph3}
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath3}
                alt="Hope for Jerome"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph4}
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph5}
              </p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath4}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p
                className="lead"
                style={{ fontSize: "0.875rem" }} // Smaller font size
              >
                {homeContent.malayalamParagraph6}
              </p>
            </Col>
          </Row>
        </>
      )
        : (
          // English Content  englishParagraph1
          <>
            <Row className="justify-content-center mb-4">
              <Col md={3} className="text-center">
                <img
                  src={homeContent.imagePath1}
                  alt="Jerome's Journey"
                  className="img-fluid rounded"
                />
              </Col>
              <Col md={8}>
                <p
                  className="lead"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}
                >
                  {homeContent.englishParagraph1}
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center mb-4">
              <Col md={8}>
                <p
                  className="lead"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}
                >
                  {homeContent.englishParagraph2}
                </p>
              </Col>
              <Col md={3} className="text-center">
                <img
                  src={homeContent.imagePath2}
                  alt="Family Support"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>

            <Row
              className="justify-content-left mb-4"
              style={{ transform: "translateY(10px)" }}
            >
              <Col md={8}>
                <p
                  className="lead"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}
                >
                  {homeContent.englishParagraph3}
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  src={homeContent.imagePath3}
                  alt="Hope for Jerome"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-4">
              <Col md={8}>
                <p
                  className="lead"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}
                >
                  {homeContent.englishParagraph4}
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img
                  src={homeContent.imagePath4}
                  alt="Family Support"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-4">
              <Col md={8}>
                <p
                  className="lead"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}
                >
                  {homeContent.englishParagraph6}
                </p>
              </Col>
            </Row>
          </>


        )}

      {/* View Updates Button and Collapsible Section */}
      <Row className="mt-4">
        <Col className="text-start">
          <Button
            onClick={handleToggleUpdates}
            aria-controls="updates-collapse-text"
            aria-expanded={open}
            variant="outline-warning"
            className="d-flex align-items-center"
          >
            {open ? "Hide Updates" : "View Updates"}
            {open ? <FaChevronUp className="ms-2" /> : <FaChevronDown className="ms-2" />}
          </Button>
          <Collapse in={open}>
            <div
              id="updates-collapse-text"
              className="mt-3"
              style={{
                maxHeight: "50vh", // Set maximum height to half the viewport height
                overflowY: "auto", // Enable vertical scrolling
                border: "1px solid #ddd", // Optional styling for border
                borderRadius: "5px", // Optional styling for rounded corners
                padding: "10px", // Optional padding for content
                backgroundColor: "#f9f9f9", // Optional background color
                fontSize: "0.875rem", // Slightly smaller font size
              }}
            >
              {loadingUpdates ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                updates.map((update, index) => (
                  <div key={index} className="mb-3">
                    <strong>
                      {new Date(update.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </strong>
                    <p style={{ fontSize: "0.875rem" }}>{update.description}</p>
                  </div>
                ))
              )}
            </div>
          </Collapse>
        </Col>
      </Row>

      {/* Add margin-top to separate from View Updates section */}
      <hr className="my-4" />
      <Row className="text-center">
        <Col>
          <div>
            <h3>Words of Support</h3>
            <p className="text-muted">Please donate to share words of support.</p>
          </div>
          <div className="text-start mt-4">
            <div
              ref={messageBoxRef} // Attach the ref to the scrollable container
              style={{
                maxHeight: "50vh", // Half the viewport height
                overflowY: "auto", // Enable scrolling
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
                fontSize: "0.875rem", // Smaller font size
              }}
            >
              {messages.length > 0 ? (
                <>
                  {messages
                    .sort((a, b) => (a.date && b.date ? new Date(b.date) - new Date(a.date) : 0))
                    .slice(0, visibleMessages)
                    .map((message, index) => (
                      <div key={index} className="my-3">
                        <strong style={{ fontSize: "0.9rem" }}>{`${message.firstname} ${message.lastname}`}</strong>
                        <p style={{ fontSize: "0.85rem" }}>{message.message}</p>
                      </div>
                    ))}
                </>
              ) : (
                <p style={{ fontSize: "0.85rem" }}>No messages to display.</p>
              )}
            </div>
            {visibleMessages < messages.length && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  setVisibleMessages((prev) => prev + 5);

                  // Smooth scrolling within the message box
                  if (messageBoxRef.current) {
                    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
                  }

                  // Optionally scroll the page to bring the box into view
                  messageBoxRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
                className="mt-3"
              >
                View More Messages
              </Button>
            )}
            {visibleMessages >= messages.length && messages.length > 0 && (
              <>
                <p className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>No more messages to show.</p>
                <Button
                  variant="outline-danger"
                  onClick={() => setVisibleMessages(0)} // Reset visible messages to hide them
                  className="mt-3"
                >
                  Close Messages
                </Button>
              </>
            )}
          </div>

        </Col>
      </Row>

      <div
        style={{
          position: 'fixed', // Always fixed for both desktop and mobile
          bottom: isDesktop ? 'auto' : '0px', // Position at the bottom for mobile
          right: isDesktop ? '50px' : '8px', // Ensure buttons are aligned to the right margin          
          top: isDesktop ? '200px' : 'auto', // Position vertically for desktop
          display: 'flex',
          flexDirection: isDesktop ? 'column' : 'row', // Stack vertically for desktop, horizontally for mobile
          alignItems: isDesktop ? 'flex-start' : 'center', // Align items based on view
          justifyContent: isDesktop ? 'flex-start' : 'space-between', // Center horizontally for mobile
          zIndex: 1000, // Ensure buttons stay on top
          backgroundColor: isDesktop ? 'transparent' : '#fff', // Optional background for mobile
          boxShadow: isDesktop ? 'none' : '0 -2px 10px rgba(0, 0, 0, 0.1)', // Add shadow for mobile
          borderRadius: isDesktop ? '0' : '10px', // Rounded corners for mobile container
          padding: isDesktop ? '0' : '8px', // Add padding for mobile
          width: isDesktop ? 'auto' : 'calc(100% - 15px)', // Full width minus padding for mobile
        }}
      >
        {/* Share Button */}
        <div style={{ marginBottom: isDesktop ? '20px' : '0', marginRight: isDesktop ? '85px' : '4px' }}>
          <ShareButton isMalayalam={isMalayalam} />
        </div>

        {/* Donate Now Button */}
        <div>
          <Link to="/donate">
            <Button variant="info" size="lg">
              Donate Now
            </Button>
          </Link>
        </div>
      </div>

      <Modal show={donationModal.show} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>{isMalayalam ? "നിങ്ങളുടെ സംഭാവന പൂർത്തിയാക്കുക" : "Please Complete Your Donation"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isMalayalam ? (
            <p>
              താങ്കൾ സംഭാവനയുടെ പ്രക്രിയ ആരംഭിച്ചിരിക്കുന്നു, പക്ഷേ, ഞങ്ങളുടെ റെക്കോർഡ് പ്രകാരം അത് ഇതുവരെ പൂർത്തിയാക്കിയിട്ടില്ല.
              അനുയോജ്യമായ ഇടപാടുമാർഗ്ഗം ഉപയോഗിച്ച് ദയവായി ഇത് പൂർത്തിയാക്കുക.
              താങ്കൾ ഇത് ഇതിനകം പൂർത്തിയാക്കിയെങ്കിൽ, <strong>ഇടപാടിന്റെ ഐഡി</strong>യും ഒരു സ്ക്രീൻഷോട്ടും താഴെ നൽകിയിരിക്കുന്ന ലിങ്ക് ഉപയോഗിച്ച് അപ്‌ലോഡ് ചെയ്യുക.
            </p>
          ) : (
            <p>
              You have already initiated the donation process but haven't completed it yet as per our records.
              Please complete it with a suitable transaction method. If you've already completed it, please
              upload the <strong>Transaction ID</strong> and a <strong>screenshot </strong> by clicking the link below.
            </p>
          )}

          <p>
            <a
              href={`https://www.support-jerome.org/confirm-donation?id=${donationModal.donation?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMalayalam ? "സംഭാവന പൂർത്തിയാക്കാൻ ഇവിടെ ക്ലിക്ക്  ചെയ്യുക" : "Clcik Here to Complete Donation"}
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {isMalayalam ? (
            <p>
              താങ്കൾക്ക്  ഇപ്പോൾ സംഭാവന ചെയ്യാൻ സാധിക്കാത്ത സാഹചര്യമാണെങ്കിൽ, മുകളിൽ കൊടുത്തുട്ടുള്ള ലിങ്കിൽ ക്ലിക്ക് ചെയ്തുകൊണ്ട് ദയവായി സംഭാവനയുടെ വിശദാംശങ്ങൾ ഡിലീറ്റ്  അല്ലെങ്കിൽ റദ്ദാക്കുക.
            </p>
          ) : (
            <p>
              If you're unable to donate now, please delete or cancel the donation details by clicking on the same link above.
            </p>
          )}
        </Modal.Footer>
      </Modal>


    </Container>
  );
};

export default Home;
