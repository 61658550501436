import React, { useState, useEffect } from 'react';
import { Table, Container, Alert, Button, Modal, Form, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import currency from 'currency.js';
import DonationProgressBar from './DonationProgressBar';


const DonationViewAdmin = () => {
  const [donations, setDonations] = useState([]);
  const [editingField, setEditingField] = useState(null); // Track the field being edited
  const [editedValue, setEditedValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({ INR: 1 });
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    fetchDonations();
    fetchExchangeRates();
  }, []);

  const handleSendReminder = async (donationId) => {
    if (!window.confirm('Are you sure you want to send a reminder email for this donation?')) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/donations/send-reminder/${donationId}`
      );
      if (response.status === 200) {
        alert('Reminder email sent successfully.');
      }
    } catch (error) {
      console.error('Error sending reminder email:', error);
      alert('Failed to send the reminder email. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const fetchDonations = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`);
      setDonations(response.data);
    } catch (error) {
      console.error('Error fetching donations:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const fetchExchangeRates = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await axios.get('https://open.er-api.com/v6/latest/INR');
      setExchangeRates(response.data.rates);
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const handleSave = async (id) => {
    if (!editingField || !editingField.field || editedValue === '') {
      alert('Invalid edit data. Please check and try again.');
      return;
    }

    setLoading(true); // Show spinner

    try {
      // Find the original object
      const originalDonation = donations.find((donation) => donation.id === id);

      if (!originalDonation) {
        setLoading(false);
        alert('Donation not found. Please refresh and try again.');
        return;
      }

      // Create updated object by merging the original with the new value
      const updatedDonation = {
        ...originalDonation,
        [editingField.field]: editedValue,
      };

      // Make the PUT request with the full object
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/donations/${id}/single-field`,
        updatedDonation
      );

      if (response.status >= 200 && response.status < 300) {
        // Update local state
        setDonations((prevDonations) =>
          prevDonations.map((donation) =>
            donation.id === id ? updatedDonation : donation
          )
        );

        setEditingField(null); // Reset editing state
        setEditedValue(''); // Clear edited value

        // Ensure spinner closes before showing the alert
        setTimeout(() => {
          setLoading(false); // Close spinner
          alert('Field updated successfully.');
        }, 100);
      } else {
        console.error('Unexpected response status:', response.status);
        setLoading(false); // Close spinner
        alert('Failed to update the field. Please try again.');
      }
    } catch (error) {
      console.error('Error updating donation:', error);

      setLoading(false); // Close spinner
      if (error.response) {
        alert(
          `Failed to update the donation. Server responded with status ${error.response.status}: ${error.response.data}`
        );
      } else {
        alert('Failed to update the donation. Please check your connection.');
      }
    }
  };


  // const handleDelete = async (id) => {
  //   if (window.confirm('Are you sure you want to delete this donation?')) {
  //     setLoading(true); // Show spinner
  //     try {
  //       setDonations((prevDonations) => prevDonations.filter((donation) => donation.id !== id));
  //       await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/donations/${id}`);
  //       alert('Donation deleted successfully.');
  //     } catch (error) {
  //       console.error('Error deleting donation:', error);
  //       alert('Failed to delete the donation.');
  //     } finally {
  //       setLoading(false); // Hide spinner
  //     }
  //   }
  // };

  const renderSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );


  const handleEdit = (id, field, value) => {
    if (!field || typeof value === 'undefined') {
      alert('Invalid field or value for editing.');
      return;
    }

    setEditingField({ id, field }); // Set the field being edited
    setEditedValue(field === 'isHiddenMessage' ? value.toString() : value); // Convert boolean to string for dropdown
  };



  const calculateTotalInINR = () => {
    if (!exchangeRates) {
      console.error('Exchange rates not loaded.');
      return 0; // Ensure return is inside the function
    }

    const total = donations
      .filter((donation) => donation.confirmationNumber) // Exclude donations with null confirmationNumber
      .reduce((sum, donation) => {
        const rate = exchangeRates[donation.currency];
        if (!rate) {
          console.error(`Exchange rate for ${donation.currency} not found. Skipping this donation.`);
          return sum; // Ensure continuation of reduce
        }

        const effectiveRate = rate < 1 ? 1 / rate : rate;
        const amountInINR = currency(donation.amount).multiply(effectiveRate).value;
        return sum + amountInINR; // Accumulate the valid donations
      }, 0);

    return Math.round(total); // Return rounded total
  };

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const renderField = (id, field, value) => {
    const isEditing = editingField?.id === id && editingField?.field === field;

    if (isEditing) {
      if (field === 'isHiddenMessage') {
        return (
          <Form.Select
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value === 'true')} // Convert to boolean
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Form.Select>
        );
      }

      const isTextAreaField = field === 'message' || field === 'address';
      return (
        <Form.Control
          as={isTextAreaField ? 'textarea' : 'input'}
          rows={isTextAreaField ? 3 : undefined}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          style={{
            width: '100%', // Expand to full column width
            minWidth: '300px', // Set a minimum width for better usability
            resize: isTextAreaField ? 'vertical' : 'none', // Allow resizing for textareas
          }}
        />
      );
    }

    // For isHiddenMessage, display "true" or "false"
    if (field === 'isHiddenMessage') {
      return <span>{value ? 'true' : 'false'}</span>;
    }

    return <span>{value || 'N/A'}</span>;
  };


  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this donation?')) {
      setLoading(true); // Show spinner
      try {
        setDonations((prevDonations) => prevDonations.filter((donation) => donation.id !== id));
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/donations/${id}`);
        alert('Donation deleted successfully.');
      } catch (error) {
        console.error('Error deleting donation:', error);
        alert('Failed to delete the donation.');
      } finally {
        setLoading(false); // Hide spinner
      }
    }
  };

  const renderActions = (id, field) => {
    if (editingField?.id === id && editingField?.field === field) {
      return (
        <Button variant="success" size="sm" onClick={() => handleSave(id)}>
          Save
        </Button>
      );
    }
    return (
      <Button
        variant="primary"
        size="sm"
        onClick={() => handleEdit(id, field, donations.find((d) => d.id === id)[field])}
      >
        Edit
      </Button>
    );
  };

  return (
    <Container className="py-5">

      <h2 className="text-center mb-4">Donation Details</h2>

      {loading ? (
        renderSpinner()
      ) : donations.length === 0 ? (
        <Alert variant="info" className="text-center">
          No donation data available.
        </Alert>
      ) : (
        <>
          {/* Desktop View */}
          <DonationProgressBar />

          <br></br>
          <Table striped bordered hover responsive className="d-none d-lg-table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Message</th>
                <th>Hidden Message</th>
                <th>Country</th>
                <th>Transaction ID</th>
                <th>Payment Method</th>
                <th>Screenshot</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {donations.map((donation, index) => (
                <tr key={donation.id}>
                  <td>{index + 1}</td>
                  <td>
                    {renderField(donation.id, 'firstname', donation.firstname)} {renderActions(donation.id, 'firstname')}
                  </td>
                  <td>
                    {renderField(donation.id, 'lastname', donation.lastname)} {renderActions(donation.id, 'lastname')}
                  </td>

                  <td>
                    {renderField(donation.id, 'email', donation.email)} {renderActions(donation.id, 'email')}
                  </td>
                  <td>
                    {renderField(donation.id, 'phone', donation.phone)} {renderActions(donation.id, 'phone')}
                  </td>
                  <td>
                    {renderField(donation.id, 'address', donation.address)} {renderActions(donation.id, 'address')}
                  </td>
                  <td>{donation.currency}</td>
                  <td>{donation.amount}</td>
                  <td>
                    {renderField(donation.id, 'message', donation.message)} {renderActions(donation.id, 'message')}
                  </td>
                  <td>
                    {renderField(donation.id, 'isHiddenMessage', donation.isHiddenMessage)}{' '}
                    {renderActions(donation.id, 'isHiddenMessage')}
                  </td>

                  <td>
                    {renderField(donation.id, 'country', donation.country)} {renderActions(donation.id, 'country')}
                  </td>
                  <td>
                    {!donation.confirmationNumber ? (
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleSendReminder(donation.id)}
                        disabled={loading}
                      >
                        Send Reminder
                      </Button>
                    ) : (
                      <span>Transaction Completed</span>
                    )}
                  </td>
                  <td>{donation.confirmationNumber}</td>
                  <td>{donation.paymentMethod}</td>
                  <td>
                    {donation.screenshotUrl ? (
                      <img
                        src={donation.screenshotUrl}
                        alt="Screenshot"
                        style={{
                          maxWidth: '100px',
                          maxHeight: '100px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(donation.screenshotUrl)}
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                  {/* <td>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(donation.id)}>
                      Delete
                    </Button>
                  </td> */}
                </tr>
              ))}
              <tr>
                <td colSpan="14" className="text-end">
                  <strong>Total Amount (INR): {calculateTotalInINR().toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </Table>

          {/* Mobile View */}
          <div className="d-lg-none">
            {donations.map((donation, index) => (
              <Card key={donation.id} className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {index + 1}. {renderField(donation.id, 'firstname', donation.firstname)} {renderActions(donation.id, 'firstname')} {renderField(donation.id, 'lastname', donation.lastname)} {renderActions(donation.id, 'lastname')}
                  </Card.Title>
                  <Card.Text>
                    <strong>Email:</strong> {renderField(donation.id, 'email', donation.email)}{' '}
                    {renderActions(donation.id, 'email')}
                    <br />
                    <strong>Phone:</strong> {renderField(donation.id, 'phone', donation.phone)}{' '}
                    {renderActions(donation.id, 'phone')}
                    <br />
                    <strong>Address:</strong> {renderField(donation.id, 'address', donation.address)}{' '}
                    {renderActions(donation.id, 'address')}
                    <br />
                    <strong>Currency:</strong> {donation.currency}
                    <br />
                    <strong>Amount:</strong> {donation.amount}
                    <br />
                    <strong>Message:</strong> {renderField(donation.id, 'message', donation.message)}{' '}
                    {renderActions(donation.id, 'message')}
                    <br />
                    <strong>Hidden Message:</strong>{' '}
                    {renderField(donation.id, 'isHiddenMessage', donation.isHiddenMessage)}{' '}
                    {renderActions(donation.id, 'isHiddenMessage')}
                    <br />
                    <strong>Country:</strong> {renderField(donation.id, 'country', donation.country)}{' '}
                    {renderActions(donation.id, 'country')}
                    <br />
                    {!donation.confirmationNumber ? (
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleSendReminder(donation.id)}
                        disabled={loading}
                      >
                        Send Reminder
                      </Button>
                    ) : (
                      <span>Transaction Completed</span>
                    )}
                    <br />
                    <strong>Transaction ID:</strong> {donation.confirmationNumber}
                    <br />
                    <strong>Payment Method:</strong> {donation.paymentMethod}
                    <br />
                    {donation.screenshotUrl && (
                      <>
                        <strong>Screenshot:</strong>
                        <br />
                        <img
                          src={donation.screenshotUrl}
                          alt="Screenshot"
                          style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                          onClick={() => handleImageClick(donation.screenshotUrl)}
                        />
                      </>
                    )}
                  </Card.Text>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(donation.id)}>
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            ))}
            {/* <Card className="mt-3">
              <Card.Body>
                <strong>Total Amount (INR): {calculateTotalInINR().toFixed(2)}</strong>
              </Card.Body>
            </Card> */}
          </div>

          {/* Modal */}
          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Screenshot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalImage ? (
                <img src={modalImage} alt="Enlarged Screenshot" style={{ width: '100%' }} />
              ) : (
                <p>No screenshot available.</p>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );

};

export default DonationViewAdmin;
