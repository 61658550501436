import React, { useState } from "react";
import axios from "axios";

const MessageSubmissionForm = () => {
  const [pastedData, setPastedData] = useState("");
  const [formattedData, setFormattedData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  const handlePaste = (e) => {
    const text = e.target.value;
    setPastedData(text);

    // Split rows by new lines and columns by tabs
    const rows = text.trim().split("\n");
    const parsedData = rows.map((row) => {
      const [firstname, lastname, message] = row.split("\t");
      return { firstname: firstname || "", lastname: lastname || "", message: message || "" };
    });

    setFormattedData(parsedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const message of formattedData) {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/messages`, message );
      setResponseMessage("Messages submitted successfully!");
    } catch (error) {
      console.error("Error submitting messages:", error);
      setResponseMessage("Error submitting messages. Please try again.");
    }
  };
}

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Message Submission</h2>
      <div className="card shadow-sm">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="pastedData" className="form-label">
                Paste Excel Data (First Name, Last Name, Message):
              </label>
              <textarea
                id="pastedData"
                className="form-control"
                rows="5"
                value={pastedData}
                onChange={handlePaste}
                placeholder="Paste data from Excel here"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit Messages
            </button>
          </form>
          {responseMessage && (
            <div
              className={`alert mt-3 ${
                responseMessage.includes("successfully") ? "alert-success" : "alert-danger"
              }`}
            >
              {responseMessage}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <h3>Formatted Data:</h3>
        <div className="bg-light p-3 rounded">
          <pre className="m-0">{JSON.stringify(formattedData, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

export default MessageSubmissionForm;
